class AutoSuggestions {
    debounceTimer = 500;

    constructor({ input = false, source = false, afterSelect = false, debounceTimer = 5000, onInputClick = false }) {
        this.input = input;
        this.source = source;
        this.onInputClick = onInputClick;
        this.afterSelect = afterSelect;
        this.debounceTimer = debounceTimer;

        this.contentContainer = false;
        this.randomIdentifier = 0;

        if (!this.input.length) return;
        this.init();
    }

    init() {
        this.randomIdentifier = Math.random()
            .toString(36)
            .slice(2);

        this.input.after(`<div class="${this.randomIdentifier} searchbar-suggestions"></div>`);
        this.contentContainer = $(`.${this.randomIdentifier}`);

        /**
         * On input change
         */
        this.input.on('keyup', () => {
            this.debouncedHandleSource();
        });

        /**
         * If already filled
         */
        this.input.on('click', () => {
            if (!this.input.val().length || !this.onInputClick) return;

            console.log('asdd');

            this.onInputClick(this.input.val(), data => {
                this.processData(data);
            });
        });

        /**
         * Close Content Container
         */
        $(document).on('click', event => {
            if (!$(event.target).closest(`.${this.randomIdentifier}`).length && !$(event.target).closest(`.js-search`).length) {
                this.closeContentContainer();
            }
        });

        /**
         * Listen to dimension change on input
         */
        let resizeObserver = new ResizeObserver(() => {
            this.recalcPosition();
        });

        resizeObserver.observe(this.input[0]);
    }

    debouncedHandleSource = _.debounce(() => {
        this.source(this.input.val(), data => {
            this.processData(data);
        });
    }, this.debounceTimer);

    recalcPosition() {
        if (!this.contentContainer) return;

        this.contentContainer.width(this.input.outerWidth());
        this.contentContainer.height('auto');
        this.contentContainer.css('position', 'absolute');
        this.contentContainer.css('top', this.input.outerHeight());
    }

    processData(data) {
        this.closeContentContainer();
        this.contentContainer.append('<ul></ul>');

        data.forEach(element => {
            this.contentContainer.find('ul').append(`
                <li data-id="id_${element}" class="searchbar-suggestions__li">${element}</li>
            `);

            let item = this.contentContainer.find(`li[data-id="id_${element}"]`);

            if (!item) return;
            item.on('click', () => {
                if (this.afterSelect) this.afterSelect(element);
            });
        });
    }

    closeContentContainer() {
        this.contentContainer.empty();
    }
}

const autoComplete = () => {
    console.log('Init AutoCompletion');
    const ajaxUrl = $('#search_ajax_autocomplete_url').val();

    if (!ajaxUrl.length) return;

    const autosuggestions = new AutoSuggestions({
        input: $('.js-search'),
        debounceTimer: 500,
        source: (query, process) => {
            $.ajax({
                url: ajaxUrl,
                type: 'GET',
                data: 'q=' + query,
                dataType: 'JSON',
                async: true,

                success: function(data) {
                    process(data);
                },
            });
        },
        afterSelect: function(target) {
            window.location.href = `${window.location.origin}/search?q=${target}`;
        },
    });
};

autoComplete();
