import Cookies from 'js-cookie';
import Sortable from 'sortablejs';
import print from './_print';

let sortableList = $('#is_bookmark_sortable_disabled').val() == 'true' ? true : false;
if (!sortableList) $('div[data-sortable="item"]').addClass('sortable__item--empty');

if (document.querySelector('div[data-sortable="list"]'))
    new Sortable(document.querySelector('div[data-sortable="list"]'), {
        animation: 150,
        ghostClass: 'sortable__item--drag',
        disabled: sortableList,
        delayOnTouchOnly: 450,
        onSort: () => updateBookmarks(),
    });

const updateBookmarks = () => {
    $.ajax($('#bookmarks_ajax_sort').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            products_id: getAllBookmarks(),
        },
        success: function(resp) {
            console.log('%c Bookmarks successfully updated!', 'color: #bada55');
        },
        error: function(resp) {
            console.log('%c Bookmarks could not be updated!', 'color: #red');
        },
    });
};

const getAllBookmarks = () => {
    let bookmarks = [];

    $('.js-bookmark-item').each(function() {
        let id = $(this).data('id');
        bookmarks.push(id);
    });

    return bookmarks.join(',') || '';
};

$(document).on('click', '.bookmarks_add_multiple', function(e) {
    e.preventDefault();
    var self = $(this);

    var ids = [];

    $('.products input[type=checkbox]:checked').each(function() {
        ids.push(
            $(this)
                .closest('tr')
                .data('id')
        );
    });

    $.ajax($('#bookmarks_ajax_add_multiple_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            products_id: ids.join(','),
        },
        success: function(resp) {
            $('.alert')
                .hide()
                .html($('#i18n_bookmarks_ajax_add_multiple_url_success').val())
                .show();

            window.location.reload();
        },
    });
});

$(document).on('click', '.bookmarks_remove', function(e) {
    var self = $(this);
    e.preventDefault();
    $.ajax($('#bookmarks_ajax_remove_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            product_id: $(this).data('id'),
        },
        success: function(resp) {
            $('.alert')
                .hide()
                .html($('#i18n_bookmarks_ajax_remove_url_success').val())
                .show();

            self.hide();
            self.parent()
                .find('.bookmarks_add')
                .show();
        },
    });
});

$(document).on('click', '.bookmark_remove_from_list', function(e) {
    // Bookmark Liste!
    var self = $(this);
    e.preventDefault();

    $.ajax($('#bookmarks_ajax_remove_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            product_id: $(this)
                .closest('.col-12')
                .data('id'),
        },
        success: function(resp) {
            $('.alert')
                .hide()
                .html($('#i18n_bookmarks_ajax_remove_url_success').val())
                .show();

            self.closest('.col-12').remove();

            if ($('#products .result').length == 0) {
                $('#no_bookmarks').show();
                $('#contact_area').hide();
            }
        },
    });
});

$(document).on('click', '.bookmarks_add', function(e) {
    var self = $(this);
    e.preventDefault();

    $.ajax($('#bookmarks_ajax_add_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            product_id: $(this).data('id'),
        },
        success: function(resp) {
            $('.alert')
                .hide()
                .html($('#i18n_bookmarks_ajax_add_url_success').val())
                .show();

            self.hide();
            self.parent()
                .find('.bookmarks_remove')
                .show();
        },
    });
});

window._loadBookmarkForm = function() {
    console.log('_loadBookmarkForm');

    if ($('.bookmarks.dropzone').length > 0) {
        $('.bookmarks.dropzone').dropzone({
            url: $('#bookmarks_ajax_upload_url').val(),
            maxFilesize: $('#_max_filesize').val(),
            paramName: 'files',
            parallelUploads: 5,
            maxFiles: 5,
            createImageThumbnails: false,
            //acceptedFiles: 'image/*,application/pdf',
            addRemoveLinks: true,
            dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
            dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
            dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
            dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
            dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
            dictResponseError: $('#i18_dropzone_dictResponseError').val(),
            dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
            dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
            dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
            dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
            dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
            dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
            init: function() {
                this.on('sending', function(e, xhr, formData) {
                    formData.append('_token', $('#_token').val());
                    formData.append('_upload_identifier', $('#upload_identifier').val());
                });

                this.on('success', function(e, xhr) {
                    console.log('success');
                    console.log(xhr);
                });
            },
        });
    }
};

$(document).on('click', '.bookmarks_submit_btn', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#bookmark_form').valid()) {
        let data = $('#bookmark_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $('#products .col-12').each(function() {
            var count = $(this)
                .find('input')
                .val();
            var id = $(this).data('id');

            data.push({
                name: 'products[]',
                value: count + '#' + id,
            });
        });

        $.post($('#bookmarks_ajax_submit_url').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                $('.alert')
                    .hide()
                    .html($('#i18n_contacts_ajax_submit_success').val())
                    .show();

                $('#no_bookmarks').show();
                $('#contact_area').hide();
                $('#products').hide();

                if ($('#is_rating_disabled').val() == '0') {
                    window.showRating();
                }
            }, 500);
        });
        return;
    }
});
