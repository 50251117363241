import Cookies from 'js-cookie';
import print from './_print';

$('.products.datatable').on('init.dt', function() {
    console.log('Checking Checkboxes of Datatable');
    $('.products.datatable input[type=checkbox]').each(function() {
        var categoryid = $('.products.datatable').data('categoryid');
        var id = $(this)
            .closest('tr')
            .data('id');

        if (Cookies.get('product_list_state_' + categoryid)) {
            var ids = Cookies.get('product_list_state_' + categoryid) ? Cookies.get('product_list_state_' + categoryid).split(',') : [];

            if (ids.indexOf('' + id) > -1) {
                $(this).attr('checked', 'checked');
            }
        }
    });

    _checkProductListInfoBar();
});

$(document).on('click', '#products_reset', function(e) {
    e.preventDefault();

    window.location = window.location.href.split('?')[0];
});

$(document).on('click', '.print-icon__details', function(e) {
    e.preventDefault();

    return print({
        url: $('#products_print_url').val() + '/' + $('#product_id').val(),
    });
});

$('.products.datatable').DataTable({
    order: [[1, 'asc']],
    info: false,
    paging: false,
    searching: false,
    scrollX: true,
    columnDefs: [
        {
            targets: 0,
            orderable: false,
        },
        {
            targets: $('.products.datatable th td').length - 1, // Actionrow!
            orderable: false,
        },
    ],
});

$(document).on('change', '.products.datatable input[type=checkbox]', function(e) {
    var state = $(this).is(':checked');
    var categoryid = $('.products.datatable').data('categoryid');
    var id = $(this)
        .closest('tr')
        .data('id');

    var ids = Cookies.get('product_list_state_' + categoryid) ? Cookies.get('product_list_state_' + categoryid).split(',') : [];

    if (!state) {
        console.log('Removing Cookie product_list_state_' + categoryid);

        if (ids.length <= 1) {
            Cookies.remove('product_list_state_' + categoryid);
        } else {
            delete ids[ids.indexOf('' + id)];

            Cookies.set('product_list_state_' + categoryid, ids.join(','));
        }

        _checkProductListInfoBar();
        return;
    }

    if (ids.indexOf('' + id) == -1) {
        ids.push(id);
    }

    console.log('Adding Cookie product_list_state_' + categoryid);

    Cookies.set('product_list_state_' + categoryid, ids.join(','));

    _checkProductListInfoBar();
});

$('.products tr').on('click', function(e) {
    if ($(e.target).prop('tagName') != 'TR' && $(e.target).prop('tagName') != 'TD') {
        return;
    }

    if ($(this).data('target')) window.location = $(this).data('target');
});

function _checkProductListInfoBar() {
    if ($('.products.datatable input[type=checkbox]:checked').length > 0) {
        $('.info_btns').show();
    } else {
        $('.info_btns').hide();
    }

    $('.products tr').each(function() {
        if (
            $(this)
                .find('input[type=checkbox]')
                .is(':checked')
        ) {
            if (!$(this).hasClass('active')) {
                $(this).addClass('active');
            }
        } else {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            }
        }
    });
}

$(document).on('click', '.products_filter_clear_btn', function(e) {
    e.preventDefault();

    window.location = window.location.href.split('?')[0];
});
