import animateScrollTo from 'animated-scroll-to';

const menu = $('.megamenu');
let navItem = $('.megamenu__item');

navItem.on('click', function(event) {
    let self = $(this);
    let element = self.attr('data-name');
    let nav = menu.find(`[data-parent="${element}"]`);

    if (nav.length >= 1) {
        hideAllMenus();
        toggleMenu(nav, self);
    }
});

$(document).on('click', '.megamenu .close', e => {
    hideAllMenus();
});

$(document).mouseup(e => {
    let menu = $('.megamenu__nav');

    if (!menu.is(e.target) && menu.has(e.target).length === 0) {
        hideAllMenus();
    }
});

const toggleMenu = (element, parent) => {
    parent.toggleClass('active');
    element.toggleClass('open');
    element.attr('megamenu-type', element.attr('megamenu-type') == 'hidden' ? 'show' : 'hidden');

    if ($(document).width() < 757) {
        animateScrollTo($(element)[0]);
    }
};

const hideMenu = element => {
    element.removeClass('open');
    element.attr('megamenu-type', 'hidden');
};

const hideAllMenus = () => {
    let menus = menu.find('[data-parent]');
    let menuParents = menu.find('[data-name]');

    menus.each(function(index, element) {
        let self = $(this);
        hideMenu(self);
    });

    menuParents.each(function(index, element) {
        let self = $(this);
        self.removeClass('active');
    });
};

const addActiveMenu = () => {
    let activeMenu = $('.js-active-menu').data('value');
    let menuItems = $('.megamenu__item');

    if (activeMenu)
        $.each(menuItems, function(index, element) {
            if ($(element).data('menu') == activeMenu) {
                $(element)
                    .find('.megamenu__item-container')
                    .addClass('active-item');
            }
        });
};

addActiveMenu();

$(document).on('click', '.category', function(e) {
    if ($(this).hasClass('.category__btn')) {
        return;
    }

    e.preventDefault();
    var btn = $(this).find('.category__btn');

    if (!btn.data('toggle') || !$(e.target).hasClass('category__btn')) {
        window.location = $(this)
            .find('a')
            .attr('data-target2');
        return;
    }
});
